@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
  font-family: "Roboto", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}

input.hideArrow::-webkit-outer-spin-button,
input.hideArrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].hideArrow {
  -moz-appearance: textfield;
  appearance: none;
}
